import Header from "../components/Header";
import Footer from "../components/Footer";

export default function PrivacyPolicy() {

    return (
        <>
            <Header />
            <div style={{ background: "linear-gradient(to bottom, #f4f4f4, white)", padding: '4% 8%' }}>
                <h2 style={{ color: 'black', margin: 0, fontWeight: 500, fontSize: 40, textAlign: 'left', marginBottom: 0 }}>
                    Privacy Policy
                </h2>
            </div>
            <div style={{ padding: '2% 8%' }}>

                <div style={{ lineHeight: '1.6', marginTop: 0 }}>
                    <p><strong>PLEASE READ THIS LEGAL DISCLAIMER AND TERMS OF SERVICE CAREFULLY BEFORE ACCESSING OUR WEBSITE OR USING OUR SERVICES</strong></p>
                    <p>A visitor to the Website (as defined below), current Customer (as defined below) or prospective Customer is subject to this Legal Disclaimer and Terms of Service (“Terms”), as set forth below.</p>

                    <h2>You and Us</h2>
                    <p>Welcome to Buzz Filing (Buzz Filing Inc..). We provide our services (“Services”) online, including via our website at <a style={{ color: "#ea2024", fontWeight: "bold" }} href="https://buzzfiling.com">buzzfiling.com</a> or any website owned by Buzz Filing (collectively the “Website”), and other forms of communications such as email. Providing information on the Website also constitutes part of the Services.</p>

                    <p>We use the term “User” or “you” or “your” or “Visitor” or “Customer” to mean any past, current, or prospective customer of our Services as well as any visitor to the Website. These Terms apply to each Customer. There will be no fees for Customers to use the Services unless stated explicitly in these Terms or in other notices from Buzz Filing to Customers such as information on the Website.</p>

                    <p>These Terms govern your access to and use of our Services. By accessing or using the Services (including accessing the Website), you agree to be bound by these Terms as if these Terms were signed by you in ink on a hard-copy agreement. We may also ask you to confirm that you agree to these Terms, including by taking particular actions, such as clicking a button labelled “I Agree” or “Buy Now” or using the Services.</p>

                    <p>Any personally-identifiable information about you or anyone else may be stored on or through the Services (“Personal Data”). So long as you are a Customer, Buzz Filing hereby grants you permission to use the software (“Software”) included in the Website as part of the Services. Your right to use the Software is revocable by Buzz Filing, and is not sublicensable. Moreover, the Software must be used solely for personal use by you.</p>

                    <p>The information provided in the Website or via any other means of transmission from Buzz Filing is not legal advice, but general information. The content contained on the Website or information contained in any other transmission from Buzz Filing is subject to these Terms.</p>

                    <h2>Informational Purposes Only</h2>
                    <p>The purpose and intent of Buzz Filing are to provide you with general information, and not to provide any specific advice (legal or otherwise). The information presented is provided solely for informational purposes and constitutes an advertisement for services. Buzz Filing does not wish to represent anyone desiring legal representation based upon viewing the Website or information provided via email, facsimile, phone conversation, or any other form of transmission.</p>

                    <h2>Information Provided As-Is</h2>
                    <p>Information obtained from Buzz Filing or the Website should NOT be used as a substitute for legal advice from an attorney. It is provided “as is”, is not guaranteed to be correct, complete or up-to-date, and Buzz Filing expressly disclaims all warranties and disclaims any and all liability of responsibility for loss, claim liability, or damage that is a result of or in any manner related to errors or omissions in the content provided by Buzz Filing or the Website.</p>

                    <h2>Content</h2>
                    <p>You shall not upload, distribute, or otherwise publish through this Site any Content, information, or other material that includes any bugs, viruses, worms, or other harmful code or properties; is libelous, threatening, defamatory, obscene, indecent, pornographic, discriminatory, or could give rise to any civil or criminal liability under the laws of the U.S. or the laws of any other country that may apply; or violates any copyrights or proprietary rights of any person.</p>

                    <h2>Minimum Age</h2>
                    <p>By accepting these Terms of Use through your use of the Site, you certify that you are 18 years of age or older. If you are under 18 years old, please use this Site only under the supervision of a parent or legal guardian.</p>

                    <h2>Privacy</h2>
                    <p>Unless explicitly permitted by our company in advance, all materials, including images, text, illustrations, and written materials that are part of this Site (collectively, the “Contents”) are intended solely for personal, non-commercial use. We reserve the right to refuse service, terminate accounts, and/or cancel orders at our discretion.</p>

                    <h2>Third-Party Sites</h2>
                    <p>The Website contains links to servers maintained by other businesses and organizations, which exist independently from Buzz Filing or the Website. Buzz Filing cannot provide any warranty about the accuracy or source of the information contained on any of these servers.</p>

                    <h2>Disclaimer of Warranties</h2>
                    <p>To the fullest extent permissible under applicable law, we disclaim any and all implied warranties and representations, including, without limitation, any warranties of merchantability, fitness for a particular purpose, title, and non-infringement.</p>

                    <h2>Limitation of Liability</h2>
                    <p>CUSTOMER HEREBY AGREES THAT IN NO EVENT SHALL Buzz Filing BE LIABLE FOR ANY DAMAGE, LOSS, CLAIM, INJURY, OR LIABILITY RESULTING FROM YOUR USE OF THE WEBSITE OR ARISING OUT OF OR IN CONNECTION WITH THE SERVICES OR INFORMATION PROVIDED BY Buzz Filing.</p>

                    <h2>Indemnification</h2>
                    <p>Customer hereby agrees to indemnify, defend and hold harmless Buzz Filing and its partners from and against all claims, losses, expenses, damages, and costs, including but not limited to legal costs and fees.</p>

                    <h2>Choice of Law and Binding Arbitration</h2>
                    <p>In the event of a dispute between you and Buzz Filing, please contact Buzz Filing customer service for resolution. Any controversy or claim arising out of or relating to the use of the Website or Services shall be submitted for final and binding arbitration.</p>

                    <h2>General Provisions</h2>
                    <p><strong>Severability:</strong> If any provision of these Terms is found by a court to be illegal, void, or unenforceable, the remaining provisions of these Terms will not be affected.</p>

                    <p><strong>Entire Agreement:</strong> These Terms constitute the entire agreement between you and us regarding the Services and supersede all prior agreements.</p>

                    <p><strong>No Informal Waivers:</strong> Any failure to act with respect to a breach of these Terms by one party does not waive the other party’s right to act with respect to that breach or subsequent breaches.</p>

                    <p><strong>Assignment and Delegation:</strong> You may not assign or delegate any rights or obligations under the Terms without the prior written permission of Buzz Filing.</p>

                    <p><strong>Customer Agreement:</strong> CUSTOMER HEREBY AGREES THAT CUSTOMER HAS READ AND AGREES WITH THIS LEGAL DISCLAIMER AND TERMS AND CONDITIONS IN ITS ENTIRETY.</p>
                </div>

            </div>
            <Footer />
        </>
    )
}