import Header from "../components/Header";
import Footer from "../components/Footer";
import { List, ListItem, ListItemText, Container, Typography } from "@mui/material";

export default function Terms() {
  const services = [
    "U.S. Company Formation (LLCs, C-Corps, etc.)",
    "EIN (Employer Identification Number) Registration",
    "Registered Agent Services",
    "Standard Business Address and Mail Forwarding",
    "FinCEN BOI Report Filing",
    "Annual Compliance and Tax Filing Assistance",
    "U.S. Business Bank Account Assistance",
    "Merchant Account Setup Assistance",
    "Business Consultation and Support",
  ];

  return (
    <>
      <Header />
      <div style={{ background: "linear-gradient(to bottom, #f4f4f4, white)", padding: '4% 8%' }}>
        <h2 style={{ color: 'black', margin: 0, fontWeight: 500, fontSize: 40, textAlign: 'left', marginBottom: 0 }}>
          Terms and Conditions
        </h2>
      </div>
      <div style={{ padding: '2% 8%' }}>

        <div style={{ lineHeight: '1.6', marginTop: 0 }}>
          <p><strong>PLEASE READ THIS LEGAL DISCLAIMER AND TERMS OF SERVICE CAREFULLY BEFORE ACCESSING OUR WEBSITE OR USING OUR SERVICES
          </strong></p>


          <p>Welcome to Buzz Filing. By using our website, services, and products, you agree to abide by the following Terms and Conditions. Please read these carefully before proceeding.
          </p>

          <h2>Introduction</h2>
          <p>Buzz Filing, a division of Buzz Filing Inc., provides U.S. company formation services, business compliance solutions, and fintech account setup assistance. These services are subject to state and federal regulations as well as third-party service provider terms and conditions.
          </p>


          <h2>Services Provided</h2>
          <p>Buzz Filing offers the following services:</p>
          <List>
            {services.map((service, index) => (
              <ListItem key={index}>
                <ListItemText primary={service} />
              </ListItem>
            ))}
          </List>
          <p>All services are subject to the applicable fees and legal requirements.</p>



          <h2>Third-Party Services</h2>
          <p>Buzz Filing assists clients in applying for fintech business bank accounts and merchant accounts (e.g., Wise, Payoneer, Mercury, Stripe, PayPal, Slash, Square, Zyla). These accounts are subject to each provider's independent review and approval process. Buzz Filing does not guarantee approval and is not responsible for any rejections.
          </p>

          <h2>Payment Terms</h2>
          <p>- A 70% upfront payment is required to initiate services, with the remaining 30% due upon EIN acquisition.
            - All payments must be made as per the agreed terms before service completion unless otherwise specified.
            - We accept payments via credit/debit card, bank transfer, and other approved methods.
            - Any state or federal fees associated with your business formation are the client’s responsibility.
          </p>

          <h2>Refund Policy</h2>

          <h3>Eligibility for Refunds</h3>

          <p>Refunds are only available under the following circumstances:</p>

          <ul>
            <li>If Buzz Filing has not initiated the service request.</li>
            <li>If there is an error on our part preventing the service from being completed.</li>
            <li>If a client cancels before the service process has begun.</li>
          </ul>

          <h3>Non-Refundable Items</h3>

          <p>The following are strictly non-refundable:</p>

          <ul>
            <li>State Filing Fees (paid directly to the state government).</li>
            <li>Registered Agent Fees (once the service is activated).</li>
            <li>Business Address Fees (once the address is assigned).</li>
            <li>Any services that have been processed, partially completed, or submitted to third-party providers.</li>
            <li>Failure to pay the remaining 30% after EIN acquisition will result in immediate suspension of services, withholding of all documents, and potential legal action for non-payment. Late fees and collection costs may also apply.</li>
            <li>If the outstanding balance remains unpaid, we reserve the right to initiate company dissolution in accordance with state laws, which may result in the permanent closure of your business.</li>
          </ul>

          <h3>Third-Party Account Refunds</h3>

          <ul>
            <li>If a third-party provider (e.g., Wise, Payoneer, Mercury, Stripe, PayPal, Slash, Square, Zyla) rejects an application, Buzz Filing is not responsible for issuing refunds.</li>
            <li>Clients must contact the respective provider for any disputes or reconsideration requests.</li>
          </ul>

          <h3>Refund Processing</h3>

          <ul>
            <li>Approved refunds will be processed within 10-15 business days.</li>
            <li>Refunds will be issued to the original payment method used.</li>
            <li>Any applicable transaction fees will be deducted from the refund amount.</li>
          </ul>

          <h2>Client Responsibilities</h2>

          <ul>
            <li>Clients must provide accurate and complete information for all service applications.</li>
            <li>Clients must comply with all U.S. business regulations and third-party provider requirements.</li>
            <li>Buzz Filing is not responsible for any legal or compliance issues arising from false or misleading information provided by the client.</li>
            <li>Clients are responsible for keeping their contact information updated to receive timely notifications regarding compliance, renewals, and other important matters.</li>
            <li>Clients must ensure timely payment for any renewals or annual compliance requirements to maintain their business in good standing.</li>
          </ul>


          <h2>Service Limitations & Liability</h2>

          <ul>
            <li>Buzz Filing does not provide legal, financial, or tax advisory services. Clients should consult licensed professionals where necessary.</li>
            <li>Buzz Filing is not responsible for delays caused by government agencies or third-party service providers.</li>
            <li>In no event shall Buzz Filing be liable for indirect, incidental, or consequential damages arising from the use of our services.</li>
            <li>Buzz Filing shall not be liable for any losses arising from the client's failure to comply with state or federal regulations.</li>
            <li>Buzz Filing is not responsible for any business decisions made by clients after forming their company.</li>
          </ul>

          <h2>Confidentiality & Data Protection</h2>

          <ul>
            <li>Buzz Filing maintains the confidentiality of all client information. Client data is securely stored and used solely for the purpose of providing services.</li>
            <li>Buzz Filing does not sell, rent, or disclose client information to third parties except as required by law.</li>
            <li>Clients are responsible for safeguarding their account login credentials and must notify Buzz Filing immediately if they suspect unauthorized access.</li>
          </ul>

          <h2>Intellectual Property Rights</h2>

          <ul>
            <li>All website content, branding, logos, and materials provided by Buzz Filing are the intellectual property of Buzz Filing Inc.</li>
            <li>Clients may not copy, distribute, or use Buzz Filing’s proprietary content without prior written consent.</li>
          </ul>


          <h2>Termination of Services</h2>

          <p>Buzz Filing reserves the right to terminate services under the following conditions:</p>

          <ul>
            <li>If a client violates any terms outlined in this document.</li>
            <li>If fraudulent or misleading information is provided.</li>
            <li>If Buzz Filing deems the request non-compliant with applicable laws.</li>
            <li>If a client engages in abusive, fraudulent, or unethical behavior toward Buzz Filing representatives.</li>
          </ul>

          <h2>Dispute Resolution</h2>

          <ul>
            <li>Any disputes arising from the use of Buzz Filing services shall first be resolved through direct communication with our support team.</li>
            <li>If a resolution cannot be reached, disputes may be subject to arbitration in accordance with applicable Pakistan laws.</li>
            <li>Clients agree to waive their right to a class-action lawsuit and instead resolve disputes on an individual basis.</li>
          </ul>

          <h2>Changes to Terms and Conditions</h2>

          <p>Buzz Filing reserves the right to update these Terms and Conditions at any time. Any changes will be posted on our website, and continued use of our services constitutes acceptance of these changes.</p>

          <h2>Contact Information</h2>

          <p>For any questions or concerns regarding these Terms and Conditions, please contact us at:</p>

          <p><strong>Buzz Filing (Buzz Filing Inc.)</strong></p>
          <p>Email: <a href="mailto:hello@buzzfiling.com" style={{ color: "#ea2024", fontWeight: "bold" }}>hello@buzzfiling.com</a></p>
          <p>Phone: <a href="tel:+13022098440" style={{ color: "#ea2024", fontWeight: "bold" }}>+1 (302) 209-8440</a></p>
          <p>Website: <a href="https://www.buzzfiling.com" target="_blank" rel="noopener noreferrer" style={{ color: "#ea2024", fontWeight: "bold" }}>www.buzzfiling.com</a></p>

          <p><strong>CUSTOMER HEREBY AGREES THAT CUSTOMER HAS READ AND AGREES WITH THIS LEGAL DISCLAIMER AND TERMS AND CONDITIONS IN ITS ENTIRETY.</strong></p>


        </div>

      </div>
      <Footer />
    </>
  )
}