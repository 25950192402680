import React, { useState } from 'react';
import { primaryColor } from '../../constant';
import { serverURL } from '../../api';
import EditMember from './EditMember';

const MembersDetails = ({ isAdmin, company }) => {

    const [open, setOpen] = useState(false)
    const [selectedMember, setSelectedMember] = useState({})

    return (
        <>
            <EditMember selectedMember={selectedMember} open={open} setOpen={setOpen} company={company} />
            <div className="company-details-container">
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '2%' }}>
                    <h3 style={{ margin: 0, marginBottom: '3%' }}>Members Details</h3>
                </div>

                <div className="company-details">
                    {company?.members?.map((member, i) => {
                        const isLastMember = i === company?.members?.length - 1;
                        return (
                            <>
                                {isAdmin && (
                                    <div
                                        onClick={() => {
                                            setSelectedMember(member)
                                            setOpen(true)
                                        }}
                                        style={{
                                            background: 'black',
                                            color: 'white',
                                            boxShadow: 'none',
                                            marginLeft: 'auto',
                                            fontSize: 13,
                                            padding: '2% 4%',
                                            border: 'none',
                                            borderRadius: 13,
                                            fontWeight: 600,
                                            cursor: 'pointer',
                                        }}
                                    >
                                        Edit
                                    </div>
                                )}
                                <div className="company-details" key={i}>
                                    {[
                                        { label: 'Name', value: `${member?.first_name} ${member?.last_name}` },
                                        { label: 'Phone', value: member?.phone },
                                        { label: 'Address', value: member?.address },
                                        { label: 'Role', value: member?.role },
                                        { label: 'Passport', value: member?.passport },
                                    ]?.map((detail, index, detailsArray) => {
                                        const isLastDetail = index === detailsArray.length - 1;
                                        return (
                                            <div
                                                className="company-detail-item"
                                                key={index}
                                                style={{
                                                    marginBottom: isLastDetail && isLastMember ? '5%' : '0',
                                                }}
                                            >
                                                <div className="company-detail-label">{detail.label}</div>
                                                <div className="company-detail-value">
                                                    {detail.label === 'Passport' ? (
                                                        detail.value ? (
                                                            <a
                                                                href={`${serverURL}/uploads/passports/${detail.value}`}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                style={{ color: primaryColor, textDecoration: 'none' }}
                                                            >
                                                                View Passport
                                                            </a>
                                                        ) : (
                                                            'N/A'
                                                        )
                                                    ) : (
                                                        detail.value || 'N/A'
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                    {!isLastMember && <hr />}
                                </div>
                            </>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default MembersDetails;
